import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'
import Login from '../components/auth/login/Login.vue'
import { navigationRoutes } from '../../src/components/admin/app-sidebar/NavigationRoutes'
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.use(Router)
Vue.use(require('vue-cookies'))

const EmptyParentComponent = {
    template: '<router-view></router-view>',
}

function loginAuthGuard(to, from, next) {
    var accessToken = Vue.$cookies.get('x-access-token')
    navigationRoutes.routes = navigationRoutes.route_bk
    if (accessToken) {
        Vue.$cookies.remove('x-access-token')
        Vue.$cookies.remove('user-id')
    }
    var isAuthenticated = localStorage.getItem('LoggedInUser')
    if (to.meta.isLoggedInCheck) {
        if (isAuthenticated) {
            next('/')
            return
        }
    }
    next()
}

export default new Router({
    // mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
    routes: [
        {
            path: '*',
            redirect: { name: 'login' },
        },
        {
            path: '/auth',
            component: AuthLayout,
            children: [
                {
                    name: 'login',
                    path: 'login',
                    meta: {
                        isLoggedInCheck: true,
                    },
                    beforeEnter: loginAuthGuard,
                    component: Login,
                },
                {
                    name: 'signup',
                    path: 'signup',
                    meta: {
                        isLoggedInCheck: true,
                    },
                    beforeEnter: loginAuthGuard,
                    component: () =>
                        import ('../components/auth/signup/Signup.vue'),
                },
                {
                    name: 'recover-password',
                    path: 'recover-password',
                    meta: {
                        isLoggedInCheck: true,
                    },
                    beforeEnter: loginAuthGuard,
                    component: () =>
                        import ('../components/auth/recover-password/RecoverPassword.vue'),
                },
                {
                    path: '',
                    redirect: { name: 'login' },
                },
            ],
        },
        {
            path: '/auth',
            component: EmptyParentComponent,
            children: [
                {
                    name: 'network',
                    path: 'network',
                    meta: {
                        isLoggedInCheck: true,
                    },
                    beforeEnter: loginAuthGuard,
                    component: () => import ('../components/interface/Interface.vue'),
                },
            ],
        },
        {
            path: '/404',
            component: EmptyParentComponent,
            children: [],
        },
        {
            name: 'Admin',
            path: '/admin',
            component: AppLayout,
            children: [
                {
                    name: 'dashboard',
                    path: 'dashboard',
                    component: () => import ('../components/dashboard/Dashboard.vue'),
                    default: true,
                },
                {
                    name: 'token',
                    path: 'token',
                    component: () => import ('../components/token/Token.vue'),
                    children: [],
                },
                {
                    name: 'backup',
                    path: 'backup',
                    component: () => import ('../components/backup/Backup.vue'),
                    children: [],
                },
                {
                    name: 'acl',
                    path: 'acl',
                    component: () => import ('../components/acl/Acl.vue'),
                    children: [],
                },
                {
                    name: 'profile',
                    path: 'profile',
                    component: () => import ('../components/profile/Profile.vue'),
                    default: true,
                },
                {
                    name: 'interface',
                    path: 'interface',
                    component: () => import ('../components/interface/Interface.vue'),
                    children: [],
                },
                {
                    name: 'config',
                    path: 'config',
                    component: () => import ('../components/config/Config.vue'),
                    children: [],
                },
                {
                    name: 'ping',
                    path: 'ping',
                    component: () => import ('../components/ping/Ping.vue'),
                    children: [],
                },
                {
                    name: 'reboot',
                    path: 'reboot',
                    component: () => import('../components/reboot/Reboot.vue'),
                    children: [],
                },
                {
                  name: 'upgrade',
                  path: 'upgrade',
                  component: () => import('../components/upgrade/Upgrade.vue'),
                  children: [],
                },
                {
                  name: 'user',
                  path: 'user',
                  component: () => import('../components/user/User.vue'),
                  children: [],
                },
                {
                  name: 'content',
                  path: 'content',
                  component: () => import('../components/content/Content.vue'),
                  children: [],
                },
                {
                  name: 'about',
                  path: 'About',
                  component: () => import('../components/about/About.vue'),
                  children: []
                },
                {
                  name: 'multicast',
                  path: 'multicast',
                  component: () => import('../components/multicast/Multicast.vue'),
                  children: []
                }
            ]
        }
    ]
})