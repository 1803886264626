<template>
  <aside
    class="app-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <ul class="app-sidebar__menu">
      <router-link
          class="mr-3"
          :to="{name:'dashboard'}"
          style="color: #ffffff; font-size: 20px;"
        ><center><b>CDN</b></center>
        </router-link>
      <template v-for="(item, key) in items">
        <div :key="key" v-if="item.meta.authorize.includes($cookies.get('userRole'))">
          <app-sidebar-link-group
            :key="key"
            :minimized="minimized"
            :icon="item.meta && item.meta.iconClass"
            v-if="item.children"
            :title="$t(item.displayName)"
            :children="item.children"
            :active-by-default="hasActiveByDefault(item)"
          >
            <app-sidebar-link
              v-for="(subMenuItem, key) in item.children"
              :key="key"
              :to="{ name: subMenuItem.name }"
              :title="$t(subMenuItem.displayName)"
            />
          </app-sidebar-link-group>
          <app-sidebar-link
            v-else
            :key="key"
            :minimized="minimized"
            :active-by-default="item.name === $route.name"
            :icon="item.meta && item.meta.iconClass"
            :to="{ name: item.name }"
            :title="$t(item.displayName)"
          />
        </div>
      </template>
      <nav
        class="app-navbar"
      >
        <div class="app-navbar__content row">
          <div class="app-navbar__menu-container">
            <va-icon-menu
              class="app-navbar__menu"
              v-if="!minimized && !isTopBar"
              @click.native="changeStatus(minimized)"
              :color="contextConfig.invertedColor ? $themes.gray : 'white'"
            />

            <va-icon-menu-collapsed
              class="app-navbar__menu"
              v-if="minimized && !isTopBar"
              @click.native="changeStatus(minimized)"
              :color="contextConfig.invertedColor ? $themes.gray : 'white'"
            />
          </div>
        </div>
      </nav>
    </ul>
  </aside>
</template>

<script>
import { navigationRoutes } from './NavigationRoutes'
import { sidebar } from '../../../i18n/sidebar.js'
import AppSidebarLink from './components/AppSidebarLink'
import AppSidebarLinkGroup from './components/AppSidebarLinkGroup'
import { ColorThemeMixin } from '../../../services/vuestic-ui'
import config from '../../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
import VaIconMenu from '../../../iconset/VaIconMenu'
import VaIconMenuCollapsed from '../../../iconset/VaIconMenuCollapsed'
import AppNavbarActions from '../app-navbar/components/AppNavbarActions'
Vue.use(vueResource)

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
    AppNavbarActions,
    VaIconMenu,
    VaIconMenuCollapsed
  },
  mixins: [ColorThemeMixin],
  props: {
    isTopBar: {
      type: Boolean,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  beforeCreate () {
    this.$themes.primary = '#2c82e0';
    this.$themes.info = '#2c82e0';
    this.$http.get(config.menu.host + 'auth/version/')
    .then(response => {
      var msg = `${this.title} `
      if(response.data.version){
        msg = msg + response.data.version
      }
      this.title = msg
    })
  },
  data () {
    return {
      title:'CDN',
      items: sidebar(),
    }
  },
  computed: {
    computedClass () {
      return {
        'app-sidebar--minimized': this.minimized,
      }
    },
    computedStyle () {
      return {
        backgroundColor: this.contextConfig.invertedColor ? 'white' : this.colorComputed,
      }
    },
  },
  methods: {
    hasActiveByDefault (item) {
      return item.children.some(child => child.name === this.$route.name)
    },
    changeStatus (minimized) {
      this.minimized = !minimized
    },
  },
}

</script>

<style lang="scss">
.app-sidebar {
  overflow: auto;
  display: flex;
  max-height: 100%;
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  &--minimized {
    flex: 0 0 3.25rem;
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
    background-color: #121212;
  }
}

$nav-border-side-width: 3.1875rem;

.app-navbar {
  transition: background-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
  display: flex;
  padding: 1rem 1rem;
  z-index: 1;

  &__content {
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    flex: 1 1 auto;
  }

  &__menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 1.5rem;
  }

  &__menu-container {
    display: flex;
    flex-wrap: nowrap;
    height: 1.5rem;
  }
}
</style>
