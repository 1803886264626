<template>
  <form @submit.prevent="onsubmit">
    <va-input
      v-model="username"
      type="text"
      label="Username"
      pattern="^[A-Za-z-()]+(?: +[A-Za-z-()]+)*$"
      :error="!!usernameErrors.length"
      :error-messages="usernameErrors"
    />

    <va-input
      v-model="password"
      type="password"
      label="Password"
      pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />
    <div v-if="err_flag" style="color: Tomato;"><label>{{err_msg}}</label></div>

    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">Login</va-button>
    </div>
  </form>
</template>

<script>
import config from '../../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { navigationRoutes } from '../../admin/app-sidebar/NavigationRoutes'


Vue.use(vueResource)
export default {
  name: 'login',
  data () {
    return {
      username: '',
      password: '',
      keepLoggedIn: false,
      usernameErrors: [],
      passwordErrors: [],
      err_flag: false,
      err_msg: '',
    }
  },
  computed: {
    formReady () {
      return !this.usernameErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    onsubmit () {
      this.usernameErrors = this.username ? [] : ['Username is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) return

      this.$http.post(config.menu.host + 'auth/login', { username: this.username, password: this.password }).then(data => {
        var response = data.body
        Vue.$cookies.set('x-access-token', response.token)
        Vue.$cookies.set('user-id', response.userId)
        Vue.$cookies.set('roles', response.role)
        Vue.$cookies.set('userRole', response.role)
        var navigation_routes = navigationRoutes.routes
        var user_role = response.role
        var routes_data_by_role = []
        for (var i = 0; i < navigation_routes.length; i++) {
          if (navigation_routes[i].meta.authorize.includes(user_role)) {
            routes_data_by_role.push(navigation_routes[i])
          }
        }
        navigationRoutes.routes = routes_data_by_role
        if(response.role == 'CONTENT_PROVIDER'){
          return this.$router.push({ name: 'content' })
        }if(response.role == 'SUPER_ADMIN'){
          this.$router.push({ name: 'dashboard' })
        } else{
          return this.$router.push({ name: 'dashboard' })
        }
      }, err => {
        this.err_flag = true
        this.err_msg = 'Invalid login credentials'
      })
    },
  },
}
</script>