<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3 flex-center">
      <router-link class="py-5 flex-center" to="/">
      </router-link>
    </div>

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-tabs
            v-model="tabIndex"
            center>
            <va-tab>Login</va-tab>
            <va-tab v-if="keyEnable">Validate Key</va-tab>
            <va-tab v-if="keyEnable">Network</va-tab>
          </va-tabs>
          <va-separator/>

          <div class="pa-3">
            <router-view/>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

const tabs = [
  'login',
  'signup',
  'network'
]

import config from '../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

export default {
  name: 'AuthLayout',
  created(){
    this.getDetails()
  },
  components: { },
  data () {
    return {
      selectedTabIndex: 0,
      keyEnable : true,
      tabTitles: ['login'],
      tabTitles: ['login', 'createNewAccount','network'],
    }
  },
  methods:{
    getDetails(){
      this.$http.get(config.menu.host + 'auth/key').then(response => {
        var data  = (response.body.length > 0) ? false : true
        Vue.$cookies.set('keyEnable', data)
        this.keyEnable = (Vue.$cookies.get('keyEnable') === 'true')
      })
    }
  },
  computed: {
    tabIndex: {
      set (tabIndex) {
        this.$router.push({ name: tabs[tabIndex] })
      },
      get () {
        this.getDetails()
        return tabs.indexOf(this.$route.name)
      },
    },
  },
}
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, #0e4ac4, #002c85);

  &__card {
    width: 100%;
    max-width: 600px;
  }

  &__options {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}
</style>
